import { createAsyncThunk } from "@reduxjs/toolkit"
import Api from "../mycomponent/Api"
import GetBranchId from "../mycomponent/GetBranchId"

export const getTherapistList = createAsyncThunk('getTherapistList', async () => {
    try {
        const response = await Api.getAsync('/api/therapist?where=is_active=1 and branch_id=' + GetBranchId() + '&order=id asc')
        return response
    }
    catch (error) {
        return error
    }
})

export const getPackageList = createAsyncThunk('getPackageList', async () => {
    try {
        const response = await Api.getAsync('/api/packageandprice?where=branch_id=' + GetBranchId() + ' &order=id asc')
        return response
    }
    catch (error) {
        return error
    }
})

export const getTreatmentList = createAsyncThunk('getTreatmentList', async () => {
    try {
        const response = await Api.getAsync('/api/treatmentandprice?where=branch_id=' + GetBranchId() + ' &order=id asc')
        return response
    }
    catch (error) {
        return error
    }
})

export const getRoomList = createAsyncThunk('getRoomList', async () => {
    try {
        const response = await Api.getAsync('/api/room?where=branch_id=' + GetBranchId() + ' &order=id asc')
        return response
    }
    catch (error) {
        return error
    }
})

export const getBedList = createAsyncThunk('getBedList', async () => {
    try {
        const response = await Api.getAsync('/api/room/bed?where=room_branch_id=' + GetBranchId() + ' &order=id asc')
        return response
    }
    catch (error) {
        return error
    }
})

export const postBooking = createAsyncThunk('postBooking', async (data) => {
    try {
        const response = await Api.post('/api/booking', data)
        return response
    }
    catch (error) {
        return error
    }
})

export const getActiveTransaction = createAsyncThunk('getActiveTransaction', async (data) => {
    try {
        const response = await Api.get('/api/transaction?where=branch_id= ' + GetBranchId() + ' and (status_id < 3 or (status_id = 3 and account_id is null))')
        return response
    }
    catch (error) {
        return error
    }
})