const server = 'https://radjasystem.com';
const mqtt = 'ws://localhost:8080';
const uniq = window.localStorage.getItem('unique_key')

const getApiAsync = (url) => {
    return fetch(server + url, {
        headers: {
            "uniq": uniq
        }
    })
        .then(response => response.json())
        .then(json => { return json })
}

const getApi = (url) => {
    return new Promise(function (resolve, reject) {
        fetch(server + url, {
            headers: {
                "uniq": uniq
            }
        })
            .then(response => {
                if (response.status == 200) {
                    resolve(response.json().then(json => { return json }))
                }
                else {
                    console.log(response.json)
                    reject(response.statusText)
                }
            })
            .catch(err => {
                console.log(err)
                reject('Koneksi ke server terputus')
            })
    })
}

var postApi = (url, data) => {
    return new Promise(function (resolve, reject) {
        fetch(server + url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "uniq": uniq
            }
        })
            .then(response => {
                if (response.status == 200) {
                    resolve(response.json().then(json => { return json }))
                }
                else {
                    reject(response.json().then(json => { return json }))
                }
            })
            .catch(err => {
                console.log(err)
                reject('Koneksi ke server terputus')
            })
    })
}

var putApi = (url, data) => {
    return new Promise(function (resolve, reject) {
        fetch(server + url, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "uniq": uniq
            }
        })
            .then(response => {
                if (response.status == 200) {
                    resolve(response.statusText)
                }
                else {
                    reject(response.json().then(json => { return json }))
                }
            })
            .catch(err => {
                console.log(err)
                reject('Koneksi ke server terputus')
            })
    })
}

var deleteApi = (url, id) => {
    return new Promise(function (resolve, reject) {
        fetch(server + url + '/' + id, {
            method: 'DELETE',
            headers: {
                "uniq": uniq
            }
        })
            .then(response => {
                if (response.status == 200) {
                    resolve(response.statusText)
                }
                else {
                    reject(response.statusText)
                }
            })
            .catch(err => {
                console.log(err)
                reject('Koneksi ke server terputus')
            })
    })
}

export default {
    server: server,
    mqtt: mqtt,
    get: getApi,
    getAsync: getApiAsync,
    post: postApi,
    put: putApi,
    delete: deleteApi,
}