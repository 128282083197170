import { createSlice } from '@reduxjs/toolkit'
import { getActiveTransaction, getBedList, getPackageList, getRoomList, getTherapistList, getTreatmentList, postBooking } from './Dashboard.api'

export const Dashboard = createSlice({
    name: 'dashboard',
    initialState: {
        therapistList: [],
        treatmentList: [],
        packageList: [],
        roomList: [],
        bedList: [],
        activeTransactionList: []
    },

    reducers: {
        increment: (state) => {
            state.value += 1
        },
        decrement: (state) => {
            state.value -= 1
        },
        incrementByAmount: (state, action) => {
            state.value += action.payload
        },
    },

    extraReducers: (builder) => {
        //
        builder.addCase(getTherapistList.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTherapistList.fulfilled, (state, action) => {
            state.loading = false
            state.therapistList = action.payload
        })
        builder.addCase(getTherapistList.rejected, (state, action) => {
            state.loading = false
        })
        //
        builder.addCase(getPackageList.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getPackageList.fulfilled, (state, action) => {
            state.loading = false
            state.packageList = action.payload
        })
        builder.addCase(getPackageList.rejected, (state, action) => {
            state.loading = false
        })
        //
        builder.addCase(getTreatmentList.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getTreatmentList.fulfilled, (state, action) => {
            state.loading = false
            state.treatmentList = action.payload
        })
        builder.addCase(getTreatmentList.rejected, (state, action) => {
            state.loading = false
        })
        //
        builder.addCase(getRoomList.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getRoomList.fulfilled, (state, action) => {
            state.loading = false
            state.roomList = action.payload
        })
        builder.addCase(getRoomList.rejected, (state, action) => {
            state.loading = false
        })
        //
        builder.addCase(getBedList.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getBedList.fulfilled, (state, action) => {
            state.loading = false
            state.bedList = action.payload
        })
        builder.addCase(getBedList.rejected, (state, action) => {
            state.loading = false
        })
        //
        builder.addCase(postBooking.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(postBooking.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(postBooking.rejected, (state, action) => {
            state.loading = false
        })
        //
        builder.addCase(getActiveTransaction.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(getActiveTransaction.fulfilled, (state, action) => {
            state.loading = false
            state.activeTransactionList = action.payload
        })
        builder.addCase(getActiveTransaction.rejected, (state, action) => {
            state.loading = false
        })
    }
})

export const {
    increment,
    decrement,
    incrementByAmount
} = Dashboard.actions

export default Dashboard.reducer