import { createSlice } from '@reduxjs/toolkit'

export const Pay = createSlice({
    name: 'pay',
    initialState: {
        isActive: false,
        activeTab: 0,
        transactionIdSelectedList: [],
        transactionSelected: []
    },

    reducers: {
        setIsActive: (state, action) => {
            state.isActive = action.payload
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setTransactionIdSelectedList: (state, action) => {
            state.transactionIdSelectedList = action.payload
        },
        setTransactionSelected: (state, action) => {
            state.transactionSelected = action.payload
        }
    },

})

export const {
    setIsActive,
    setActiveTab,
    setTransactionIdSelectedList,
    setTransactionSelected
} = Pay.actions

export default Pay.reducer