import { configureStore } from '@reduxjs/toolkit'
import BookingReducer from './views/dashboard/booking/Booking.reducer'
import DashboardReducer from './views/dashboard/Dashboard.reducer'
import PayReducer from './views/dashboard/pay/Pay.reducer'

const store = configureStore({
  reducer: {
    Dashboard: DashboardReducer,
    Booking: BookingReducer,
    Pay: PayReducer,
  },
})

export default store