import { createSlice } from '@reduxjs/toolkit'

export const Booking = createSlice({
    name: 'booking',
    initialState: {
        isActive: false,
        activeTab: 0,
        memberSelected: {},
        nonMemberName: "",
        nonMemberPhone: "",
        treatmentListSelected: [],
        packageListSelected: [],
        timeSelected: "",
        therapistSelected: {},
        bedSelected: {}
    },

    reducers: {
        setIsActive: (state, action) => {
            state.isActive = action.payload
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload
        },
        setMemberSelected: (state, action) => {
            state.memberSelected = action.payload
        },
        setNonMemberName: (state, action) => {
            state.nonMemberName = action.payload
        },
        setNonMemberPhone: (state, action) => {
            state.nonMemberPhone = action.payload
        },
        setTreatmentListSelected: (state, action) => {
            state.treatmentListSelected = action.payload
        },
        setPackageListSelected: (state, action) => {
            state.packageListSelected = action.payload
        },
        setTimeSelected: (state, action) => {
            state.timeSelected = action.payload
        },
        setTherapistSelected: (state, action) => {
            state.therapistSelected = action.payload
        },
        setBedSelected: (state, action) => {
            state.bedSelected = action.payload
        }
    },

})

export const {
    setIsActive,
    setActiveTab,
    setMemberSelected,
    setNonMemberName,
    setNonMemberPhone,
    setTreatmentListSelected,
    setPackageListSelected,
    setTimeSelected,
    setTherapistSelected,
    setBedSelected
} = Booking.actions

export default Booking.reducer